export const apiUrls = {
  country: 'api/Country',
  state: 'api/State',
  stateType: 'api/StateType',
  city: 'api/City',
  fir: 'api/FIR',
  event: 'api/Event',
  region: 'api/Region',
  regionType: 'api/RegionType',
  associatedRegion: 'api/AssociatedRegion',
  territoryType: 'api/TerritoryType',
  continent: 'api/Continent',
  metro: 'api/Metro',
  cappsTerritoryType: 'api/CAPPSTerritoryType',
  accessLevel: 'api/AccessLevel',
  sourceType: 'api/SourceType',
  island: 'api/Island',
  referenceData: 'api/referenceData',
  securityThreatLevel: 'api/SecurityThreatLevelSetting',
  navblueCountryMapping: 'api/NavBlueCountryCodeMapping',
  audit: 'api/AuditHistory',
  aip: 'api/AeronauticalInformationPublication',
  associatedAip: 'api/AssociatedAeronauticalInformationPublication',
  aipSourceType: 'api/AIPSourceType',
  navigator: 'api/Navigator',
  cabotageExemptionLevel: 'api/ExemptionLevel',
  weaponInformation: 'api/WeaponInformation',
  item18Content:'api/Item18Content',
  aircraftEquipment:'api/AircraftEquipment',
  apisRequirement:'api/APISRequirement',
  disinsectionType:'api/DisinsectionType',
  disinsectionChemical:'api/DisinsectionChemical',
  apisSubmission:'api/APISSubmission',
  declarationForCashCurrency:'api/DeclarationRequiredForCashCurrency',
  cabotage: 'CabotageOperationalRequirement',
  flightPlanning: 'FlightPlanningOperationalRequirement',
  requirementType:'api/RequirementType',
  customs: 'CustomsOperationalRequirement',
  documents: 'api/Document',
  flightOperationalCategory: 'api/FlightOperationalCategory',
  general: 'GeneralOperationalRequirement',
};
