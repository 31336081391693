import { CoreModel, IdNameCodeModel } from '@wings-shared/core';
import { BULLETIN_COMPARISON_TYPE, BULLETIN_MERGE_STATUS } from '../Enums';
import { IAPIUplinkStagingProps } from '../Interfaces';

export class UplinkStagingPropsModel extends CoreModel {
  tableName: string;
  propertyName: string;
  oldValue: string;
  newValueId: number | null;
  newValueCode: string | IdNameCodeModel;
  newValue: string;
  isList: boolean = false;
  mergeStatus: BULLETIN_MERGE_STATUS;
  comparisonType?: BULLETIN_COMPARISON_TYPE;

  constructor(data?: Partial<UplinkStagingPropsModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: IAPIUplinkStagingProps): UplinkStagingPropsModel {
    if (!apiData) {
      return new UplinkStagingPropsModel();
    }
    const data: Partial<UplinkStagingPropsModel> = {
      ...apiData,
      ...CoreModel.deserializeAuditFields(apiData),
    };
    return new UplinkStagingPropsModel(data);
  }

  static deserializeList(apiDataList: IAPIUplinkStagingProps[]): UplinkStagingPropsModel[] {
    return apiDataList
      ? apiDataList.map((apiData: IAPIUplinkStagingProps) => UplinkStagingPropsModel.deserialize(apiData))
      : [];
  }
}
