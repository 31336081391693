import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  icons: {
    width: '20px',
    height: '20px',
  },
  actionButton: {
    paddingTop: '10px',
  },
});
