export enum COUNTRY_AUDIT_MODULES {
  COUNTRY = 'Country',
  STATE = 'State',
  CITY = 'City',
  ISLAND = 'Island',
  CONTINENT = 'Continent',
  REGION = 'Region',
  ASSOCIATEDREGION = 'AssociatedRegion',
  FIR = 'FIR',
  METRO = 'Metro',
}
