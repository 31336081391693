export enum SETTING_CATEGORIES {
  GENERAL = 1,
  BULLETIN,
  OPERATIONAL_REQUIREMENTS,
}

export enum SETTING_ID {
  TERRITORY_TYPE = 1,
  CAPPS_TERRITORY_TYPE,
  REGION_TYPE,
  STATE_TYPE,
  SECURITY_THREAT_LEVEL,
  AIP_SOURCE_TYPE,
  SOURCE_TYPE,
  ACCESS_LEVEL,
  BULLETIN_LEVEL,
  BULLETIN_TYPE,
  BULLETIN_SOURCE,
  BULLETIN_PRIORITY,
  NAVIGATOR_FLIGHT_TYPE,
  CABOTAGE_EXEMPTION_LEVEL,
  ITEMS_18_CONTENTS,
  AIRCRAFT_EQUIPMENT,
  DISINSECTION_TYPE,
  DISINSECTION_CHEMICAL,
  APIS_REQUIREMENT,
  WEAPON_INFORMATION,
  APIS_SUBMISSION,
  DECLARATION_FOR_CASH_CURRENCY,
  REQUIREMENT_TYPE,
  NAVBLUE_COUNTRY_MAPPING,
  CAPPS_CATEGORY_CODE,
  FEE_REQUIREMENT
}
