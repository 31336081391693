export enum STATE_FILTERS {
  COUNTRY_CODE = 'Country Code',
  STATE_CODE = 'State Code',
  ISO_STATE_CODE = 'ISO Code',
  STATE_TYPE = 'State Type',
  COMMON_NAME = 'Common Name',
  OFFICIAL_NAME = 'Official Name',
  CAPPS_CODE = 'CAPPS Code',
  CAPPS_NAME = 'CAPPS Name',
}
