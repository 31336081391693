import {
  BaseCountryStore,
  HttpClient,
  baseApiPath,
  CabotageModel,
  IAPICabotage,
  IAPIFlightPlanning,
  FlightPlanningModel,
  GeneralModel,
  IAPIGeneral,
} from '@wings/shared';
import { apiUrls } from './API.url';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { Logger } from '@wings-shared/security';
import { IBaseApiResponse } from '@wings-shared/core';

export class OperationalRequirementStore extends BaseCountryStore {

  /* istanbul ignore next */
  public getCabotage(request?: IAPICabotage): Observable<CabotageModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.noSqlData });
    return http.get<IAPICabotage>(`${apiUrls.referenceData}`).pipe(
      Logger.observableCatchError,
      map(response => ({ ...response, results: CabotageModel.deserialize(response.results) }))
    );
  }

  /* istanbul ignore next */
  public getGeneral(request?: IAPIGeneral): Observable<GeneralModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.noSqlData });
    return http.get<IAPICabotage>(`${apiUrls.referenceData}`).pipe(
      Logger.observableCatchError,
      map(response => ({ ...response, results: GeneralModel.deserialize(response.results) }))
    );
  }

  /* istanbul ignore next */
  public getFlightPlanning(request?: IAPIFlightPlanning): Observable<FlightPlanningModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.noSqlData });
    return http.get<IAPIFlightPlanning>(`${apiUrls.referenceData}`).pipe(
      Logger.observableCatchError,
      map(response => ({ ...response, results: FlightPlanningModel.deserialize(response.results) }))
    );
  }

  /* istanbul ignore next */
  public upsertRequirement<T extends IBaseApiResponse>(
    request: T,
    requirementType: string,
    path: string
  ): Observable<T> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.countries });
    const _apiPath = `${apiUrls.country}/${(request as any).countryId}/${path}`;
    const isNewRequest: boolean = (request as any).id === 0;
    const upsertRequest: Observable<T> = isNewRequest
      ? http.post<T>(_apiPath, request)
      : http.put<T>(`${_apiPath}/${request.id}`, request);

    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => response),
      tap(() => AlertStore.info(`${requirementType} ${isNewRequest ? 'created' : 'updated'} successfully!`))
    );
  }
}
