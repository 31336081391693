export enum BULLETIN_REVIEW_FILTERS {
  ENTITY = 'Bulletin Entity',
  LEVEL = 'Bulletin Level',
  STATUS = 'Status',
}

export enum BULLETIN_MERGE_STATUS {
  NOT_MERGED = 1,
  MERGED,
  FAILED,
  REJECTED,
}

export enum BULLETIN_COMPARISON_TYPE {
  ADDED = 1,
  MODIFIED,
}
