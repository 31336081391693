export enum COUNTRY_GRID_FIELDS {
  ISO2CODE = 'ISO2Code',
  ISO3CODE = 'ISO3Code',
  OFFICIALNAME = 'OfficialName',
  COMMONNAME = 'CommonName',
  ISTERRITORY = 'IsTerritory',
  SOVEREIGNCOUNTRYNAME = 'SovereignCountryName',
  STATUS = 'Status.Name',
  ACCESSLEVEL = 'AccessLevel.Name',
  SOURCETYPE = 'SourceType.Name',
}
